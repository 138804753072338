"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/profile.ts
var profile_exports = {};
__export(profile_exports, {
  default: () => Profile
});
module.exports = __toCommonJS(profile_exports);
var Profile = class {
  address;
  background;
  color;
  command;
  created_at;
  deliveryLocal;
  description;
  fees;
  formsPayment;
  id;
  logo;
  minval;
  minvalLocal;
  name;
  not_security_key;
  options;
  request;
  showTotal;
  slug;
  status;
  taxDelivery;
  timeZone;
  typeDelivery;
  typeStore;
  // categories: Category[];
  updated_at;
  userId;
  week;
  whatsapp;
  firstOnlyCupom;
  constructor(profile) {
    this.address = profile.address;
    this.background = profile.background;
    this.color = profile.color;
    this.command = profile.command;
    this.created_at = profile.created_at;
    this.deliveryLocal = profile.deliveryLocal;
    this.description = profile.description;
    this.fees = profile.fees;
    this.formsPayment = profile.formsPayment;
    this.id = profile.id;
    this.logo = profile.logo;
    this.minval = profile.minval ? (typeof profile.minval === "number" ? profile.minval : Number(profile.minval)).toFixed(2) : "";
    this.minvalLocal = profile.minvalLocal ? (typeof profile.minvalLocal === "number" ? profile.minvalLocal : Number(profile.minvalLocal)).toFixed(2) : "";
    this.name = profile.name;
    this.not_security_key = profile.not_security_key;
    this.options = profile.options;
    this.request = profile.request;
    this.showTotal = profile.showTotal;
    this.slug = profile.slug;
    this.status = profile.status;
    this.taxDelivery = profile.taxDelivery;
    this.timeZone = profile.timeZone;
    this.typeDelivery = profile.typeDelivery;
    this.typeStore = profile.typeStore;
    this.updated_at = profile.updated_at;
    this.userId = profile.userId;
    this.week = profile.week;
    this.whatsapp = profile.whatsapp;
    this.firstOnlyCupom = profile.firstOnlyCupom;
    if (this.options && this.options.placeholders && !this.options.placeholders?.clientText) {
      this.options.placeholders.clientText = "Ol\xE1 [NOME], Tudo bem?";
    }
    if (this.options) {
      if (this.options.print.copies < 0) {
        this.options.print.copies = 1;
      } else if (this.options.print.copies > 100) {
        this.options.print.copies = 100;
      }
    }
  }
  headers = (token) => {
    return new Headers({
      Authorization: `Basic ${token}`,
      "Content-Type": "application/json"
    });
  };
  weekDate = () => {
    Object.keys(this.week).forEach((day) => {
      this.week[day];
    });
  };
};
