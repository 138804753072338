import { CartsContext } from '@context/cart.ctx'
import { useContext, useState } from 'react'
import { Button, Form, Modal, ModalProps } from 'react-bootstrap'
import { api } from 'src/lib/axios'
import { FC } from 'react'
import { AppContext } from '@context/app.ctx'
import Cart from '../../../types/cart'

interface Reason {
  cancellationCode: string
  reason?: string
}

interface RefuseOrderModalProps extends ModalProps {
  reasons: Reason[]
  onCancelOrder: (data: { order: any }) => void
}

export const RefuseOrderModal: FC<RefuseOrderModalProps> = ({ reasons, onCancelOrder, ...props }) => {
  const [reasonSelected, setReasonSelected] = useState<Reason>({
    cancellationCode: '0',
    reason: '',
  })

  const cancelOrder = async ({ reasons, orderId }: { reasons: any; orderId: string }) => {
    try {
      if (reasons.reason === undefined) {
        reasons.reason = ''
      }

      const { data } = await api.post(`/dashboard/ifood/order/${orderId}/updateStatus`, { cancellationReason: reasons, status: 'CANCELLED' })

      onCancelOrder(data)
    } catch (error) {
      console.error('erro ao cancelar o pedido no ifood', error)
    }
  }

  return (
    <Modal {...props} centered backdrop="static" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999999 }}>
      <Modal.Header closeButton>
        <h3 className="fw-bold m-0">Recusa de Pedidos iFood</h3>
      </Modal.Header>

      <Modal.Body style={{ height: '15rem' }}>
        <Form.Label>Selecione o Motivo da Recusa do Pedido</Form.Label>
        <Form.Select
          onChange={(e) =>
            setReasonSelected({
              cancellationCode: String(e.target.value),
              reason: e.target.selectedOptions ? e.target.selectedOptions.item(0)?.id : '',
            })
          }
        >
          <option value="">Selecionar...</option>
          {reasons.map((reason) => (
            <option value={reason.cancellationCode} id={reason.reason} key={reason.cancellationCode}>
              {reason.reason}
            </option>
          ))}
        </Form.Select>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="danger"
          disabled={reasonSelected.cancellationCode === '' || !props.orderId}
          onClick={() => {
            if (props.orderId) {
              cancelOrder({ reasons: reasonSelected, orderId: props.orderId })
            } else {
              console.error('Order ID não encontrado!')
            }
          }}
        >
          Cancelar Pedido
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

interface IfoodModalProps {
  show: boolean
}

export function ModalIfoodNewOrders({ show }: IfoodModalProps) {
  const { setRequestsToPrint, profile } = useContext(AppContext)
  const { newPlacedOrders, setNewPlacedOrders } = useContext(CartsContext)
  const [showReasons, setShowReasons] = useState(false)
  const [reasons, setReasons] = useState<Reason[]>([])

  const confirmOrder = async ({ order }: { order: any }) => {
    try {
      const { data } = await api.post(`/dashboard/ifood/order/${order.orderId}/updateStatus`, { status: 'CONFIRMED' })

      setNewPlacedOrders((prev) => ({ ...prev, orders: prev.orders.filter((order: any) => order.orderId !== data.order.orderId) }))
    } catch (error) {
      console.error('erro ao confirmar o pedido no ifood', error)
    }
  }

  const cancellationReasons = async (orderId: string) => {
    try {
      const { data } = await api.get(`/dashboard/ifood/order/${orderId}/cancellationReasons`)

      data.forEach((reasons: any) => {
        if (reasons) {
          reasons.cancellationCode = reasons.cancelCodeId
          reasons.reason = reasons.description

          delete reasons.cancelCodeId
          delete reasons.description
        }
      })
      setReasons(data)
      setShowReasons(true)
    } catch (error) {
      console.error('erro ao cancelar o pedido no ifood', error)
    }
  }

  const typeOrderOfCarts = () => {
    let type
    if (newPlacedOrders.orders[0].address) {
      type = 'Delivery'
    }
    if (!newPlacedOrders.orders[0].address) {
      type = 'Retirada'
    }
    return type
  }

  const typeOrderOfIfood = () => {
    let type
    if (newPlacedOrders.orders[0]?.orderType === 'DELIVERY') {
      type = 'Delivery'
    }
    if (newPlacedOrders.orders[0]?.orderType === 'TAKEOUT') {
      type = 'Retirada'
    }
    return type
  }

  return (
    <>
      {newPlacedOrders.orders.length && (
        <>
          <Modal show={show} centered backdrop="static">
            <Modal.Header>
              <h3 className="fw-bold m-0">Pedido IFood</h3>
            </Modal.Header>

            <Modal.Body className="p-0">
              <div className="d-flex flex-column p-3 border boder-bottom gap-2">
                <div>
                  <span className="fw-bold">
                    Cód. Pedido: {newPlacedOrders.orders[0].displayId ? newPlacedOrders.orders[0]?.displayId : newPlacedOrders.orders[0].code}
                  </span>
                </div>
                {newPlacedOrders.orders[0]?.delivery && (
                  <div>
                    <span className="fw-bold">
                      Cód. Coleta do Pedido:{' '}
                      {newPlacedOrders.orders[0].displayId
                        ? newPlacedOrders.orders[0]?.delivery?.pickupCode
                        : newPlacedOrders.orders[0]?.controls?.pickupCode}
                    </span>
                  </div>
                )}
                <div>
                  <span className="fw-bold">
                    Nome: {newPlacedOrders.orders[0].displayId ? newPlacedOrders.orders[0]?.customer.name : newPlacedOrders.orders[0].client.name}
                  </span>
                </div>
                {newPlacedOrders.orders[0]?.customer.secretNumber && (
                  <div>
                    <span className="fw-bold">
                      CPF:{' '}
                      {newPlacedOrders.orders[0].displayId
                        ? newPlacedOrders.orders[0]?.customer.secretNumber
                        : newPlacedOrders.orders[0].secretNumber}
                    </span>
                  </div>
                )}
                <div>
                  <span className="fw-bold">
                    Total:{' '}
                    {newPlacedOrders.orders[0].displayId
                      ? newPlacedOrders.orders[0]?.total.orderAmount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                      : newPlacedOrders.orders[0].formsPayment[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </span>
                </div>
                <div>
                  <span className="fw-bold">
                    Pagamento:{' '}
                    {newPlacedOrders.orders[0].displayId
                      ? newPlacedOrders.orders[0]?.payments[0].methods[0]?.card
                        ? newPlacedOrders.orders[0]?.payments[0].methods[0]?.card.brand
                        : 'Dinheiro'
                      : Array.from(new Set(newPlacedOrders.orders[0].formsPayment.map((formPayment: any) => formPayment.label))).join(', ')}
                  </span>
                </div>
                <div>
                  <span className="fw-bold">Tipo: {newPlacedOrders.orders[0].displayId ? typeOrderOfIfood() : typeOrderOfCarts()}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between p-3 gap-2" style={{ overflowY: 'auto', maxHeight: '280px' }}>
                <div>
                  <p className="fw-bold">Itens</p>
                  <p className="">
                    {newPlacedOrders.orders[0]?.itens.map((item: any) => {
                      return (
                        <>
                          {newPlacedOrders.orders[0].displayId ? (
                            <>
                              <div className="d-flex justify-content-between m-0">
                                <p className="col-8 col-sm-6">{item.name}</p>
                                <p className="d-flex justify-content-start col-3 col-sm-3 fw-bold">
                                  {' '}
                                  <span className="pe-2">-</span> {item.quantity} Sabor
                                </p>
                                <p className="d-flex justify-content-end col-3 col-sm-5">
                                  {item.index
                                    ? item.totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    : item.details.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </p>
                              </div>
                              {item.complements && (
                                <p>
                                  Complementos:
                                  {item.complements?.map((complement: any) => {
                                    return (
                                      <>
                                        <div className="d-flex gap-5">
                                          <p>
                                            {complement.quantity}X |{complement.name}
                                          </p>
                                          <p>
                                            {complement.price > 0
                                              ? complement.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                              : ''}
                                          </p>
                                        </div>
                                      </>
                                    )
                                  })}
                                </p>
                              )}
                              {item.observations && <p>OBS.: {item.observations}</p>}
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-between m-0">
                                <p className="col-8 col-sm-6">{item.name}</p>
                                <p className="d-flex justify-content-start col-3 col-sm-3 fw-bold">
                                  {' '}
                                  <span className="pe-2">-</span> {item.quantity} Sabor
                                </p>
                                <p className="d-flex justify-content-end col-3 col-sm-5">
                                  {item.index
                                    ? item.totalPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                    : newPlacedOrders.orders[0].total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </p>
                              </div>
                              {item.details.complements && (
                                <p>
                                  Complementos:
                                  {item.details.complements?.map((complement: any) => {
                                    return (
                                      <>
                                        <div className="d-flex gap-5">
                                          <p>
                                            {complement.quantity}X |{complement.name}
                                          </p>
                                          <p>
                                            {complement.price > 0
                                              ? complement.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                              : ''}
                                          </p>
                                        </div>
                                      </>
                                    )
                                  })}
                                </p>
                              )}
                              {item.obs && <p>OBS.: {item.obs}</p>}
                            </>
                          )}
                        </>
                      )
                    })}
                  </p>
                </div>
                <div>
                  <p className="fw-bold me-2">Preços</p>
                  <p>
                    {newPlacedOrders.orders[0]?.itens.map((item: any) => {
                      return <></>
                    })}
                  </p>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="danger" onClick={() => cancellationReasons(newPlacedOrders.orders[0]?.orderId)}>
                Recusar Pedido
              </Button>
              <Button onClick={() => confirmOrder({ order: newPlacedOrders.orders[0] })}>Aceitar Pedido</Button>
            </Modal.Footer>
          </Modal>
          <RefuseOrderModal
            show={showReasons}
            orderId={newPlacedOrders.orders[0]?.orderId}
            reasons={reasons}
            onHide={() => setShowReasons(false)}
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            onCancelOrder={(data) => {
              setShowReasons(false)
              setNewPlacedOrders((prev) => ({ ...prev, orders: prev.orders.filter((order: any) => order.orderId !== data.order.orderId) }))
            }}
          />
        </>
      )}
    </>
  )
}
