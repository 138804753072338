import axios from 'axios'

export const apiV2 = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WHATSMENU_API,
})

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WHATSMENU_API_V3,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const groveNfeApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GROVE_NFE_URL,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_GROVE_NFE_TOKEN}`,
    'Content-Type': 'application/json',
  },
})

export const grovePayApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_GROVE_PAY_URL,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_GROVE_PAY_TOKEN}`,
    'Content-Type': 'application/json',
  },
})