'use client'

import { OverlaySpinner } from '@components/OverlaySpinner'
import { AppContext } from '@context/app.ctx'
import { CartsContext } from '@context/cart.ctx'
import { zodResolver } from '@hookform/resolvers/zod'
import Cart from '../../../types/cart'
import { CartType } from '@whatsmenu/entities'
import i18next from '@whatsmenu/i18n'
import { useContext } from 'react'
import { Button, Col, Modal, ModalProps, Row, Table } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { BsTrash } from 'react-icons/bs'
import { api } from 'src/lib/axios'
import * as z from 'zod'

const cartFormSchema = z.object({
  items: z.array(
    z.object({
      id: z.number(),
      quantity: z.number(),
      name: z.string(),
      key: z.number().optional(),
      details: z.object({
        value: z.number(),
      }),
    })
  ),
}).transform((data) => ({
  items: data.items.map((item) => item.id)
}))

type CartFormInputData = z.input<typeof cartFormSchema>
type CartFormOutputData = z.output<typeof cartFormSchema>

interface EditCartModalProps extends ModalProps {
  cart: Cart
}

export const EditCartModal = ({
  ...props
}: EditCartModalProps) => {
  const { handleShowToast } = useContext(AppContext)
  const { setCart, carts } = useContext(CartsContext)
  const form = useForm<CartFormInputData, any, CartFormOutputData>({
    resolver: zodResolver(cartFormSchema),
  })
  const { fields, remove } = useFieldArray({
    control: form.control,
    name: 'items',
    keyName: 'key',
  })

  const totalPrice = fields.reduce(
    (sum, item) => sum + item.details.value * item.quantity,
    0
  )

  const onSubmit = async (body: CartFormOutputData) => {
    try {
      const {
        data: { cart },
      } = await api.patch<{ cart: CartType }>(
        `/dashboard/carts/${cartId}/update`,
        {
          items: body.items,
        }
      )
      props.cart.itens = new Cart(cart).itens
      setCart(props.cart)
      handleShowToast({ type: 'success', title: 'Carrinho atualizado' })
      if (props.onHide) {
        props.onHide()
      }
    } catch (error) {
      handleShowToast({ type: 'erro', title: 'Carrinho atualizado' })
      console.error(error)
    }
  }

  if (!props.cart) {
    return null
  }

  const { id: cartId, itens: cartItems } = props.cart

  return (
    <Modal
      {...props}
      centered
      size="lg"
      style={{ zIndex: 9999999 }}
      onShow={() =>
        form.reset({
          items: cartItems
            .filter((item) => item.deleted_at === null)
            .map((item) => item),
        })
      }
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Remover itens do carrinho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fields.length === 0 ? (
            <div className="py-4 text-center">
              <p className="text-muted">Seu carrinho está vazio</p>
            </div>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Produto</th>
                    <th className="text-end">Preço</th>
                    <th className="text-end">Quantidade</th>
                    <th className="text-end">Subtotal</th>
                    <th style={{ width: '80px' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map((item, index) => (
                    <tr key={item.key}>
                      <td>{item.name}</td>
                      <td className="text-end">
                        R$ {item.details.value.toFixed(2)}
                      </td>
                      <td className="text-end">{item.quantity}</td>
                      <td className="fw-bold text-end">
                        R$ {(item.details.value * item.quantity).toFixed(2)}
                      </td>
                      <td>
                        <Button
                          variant="link"
                          className="text-danger text-decoration-none mx-auto p-0"
                          type="button"
                          disabled={form.formState.isSubmitting}
                          onClick={() => {
                            remove(index)
                          }}
                        >
                          <BsTrash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Row className="mt-4">
                <Col>
                  <p className="fw-bold mb-0">Total</p>
                  <p className="text-muted small">
                    {fields.reduce((sum, item) => sum + item.quantity, 0)} itens
                  </p>
                </Col>
                <Col className="text-end">
                  <p className="fs-4 fw-bold">R$ {totalPrice.toFixed(2)}</p>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end gap-3">
          <Button variant="outline-secondary" onClick={props.onHide}>
            Cancelar
          </Button>
          <Button variant="primary" type="submit">
            Remover
          </Button>
        </Modal.Footer>
      </form>
      <OverlaySpinner
        show={form.formState.isSubmitting}
        textSpinner={i18next.t('loading')}
      />
    </Modal>
  )
}
