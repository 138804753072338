"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/complements.ts
var complements_exports = {};
__export(complements_exports, {
  default: () => Complement
});
module.exports = __toCommonJS(complements_exports);
var Complement = class {
  id;
  type;
  name;
  order;
  min;
  max;
  vinculate;
  required;
  itens;
  pivot;
  created_at;
  updated_at;
  constructor(complement) {
    ;
    this.id = complement.id, this.name = complement.name, this.type = complement.type, this.order = complement.order, this.min = complement.min, this.max = complement.max, this.required = complement.required, this.itens = complement.itens ?? [], this.pivot = complement.pivot, this.created_at = complement.created_at, this.updated_at = complement.updated_at;
    this.itens.forEach((item) => {
      item.value = Number(item.value ?? 0);
      item.amount_alert = item.amount_alert ?? 0;
      item.description = item.description ?? "";
    });
  }
  /** Verifica se existem outros complementos com mesmo id e determina se é vinculado, se modifyComplements a função altera os complementos iguais
   *
   * @param {Complement[]} complements - Array de complementos
   * @param {boolean | undefined} modifyComplements - Verdadeiro ou falso para modificação dos complementos
   *
   **/
  isLinked = (complements, modifyComplements) => {
    const allComplements = complements.filter((comp) => comp.id === this.id);
    if (modifyComplements) {
      allComplements.forEach((compl) => {
        compl.name = this.name;
        compl.max = this.max;
        compl.min = this.min;
        compl.itens = this.itens;
        compl.required = this.required;
      });
    }
    return allComplements.length > 1;
  };
  getTotal() {
    let total = 0;
    for (const item of this.itens) {
      total += item.quantity ? item.value * item.quantity : item.value;
    }
    return total;
  }
  static removeProps = (complement) => {
    const { id: deletedId, created_at, updated_at, pivot, ...newComplement } = complement;
    return newComplement;
  };
  static toDuplicate(complements) {
    return complements.map((comp) => {
      return {
        name: comp.name,
        max: comp.max,
        min: comp.min,
        itens: comp.itens,
        required: comp.required,
        order: comp.order
      };
    });
  }
  static verifyEqualsComplements = (complements, complementsVerify) => {
    return complements.every((compl) => {
      return complementsVerify.some((complV) => {
        if (complV.id === compl.id && compl.itens.length === complV.itens.length) {
          return complV.itens.every((cvItem) => compl.itens.some((cItem) => cItem.code === cvItem.code && cItem.quantity === cvItem.quantity));
        }
      });
    });
  };
};
