import { useEffect, useState } from 'react'
import { CartType } from '@whatsmenu/entities/dist/cart'
import Profile, { ProfileType } from '@whatsmenu/entities/dist/profile'
import { useSession } from 'next-auth/react'

export const useWhatsAppBot = () => {
  const [canUseWhatsMenuDesktop, setCanUseWhatsMenuDesktop] = useState(false)
  const { data: session, status } = useSession();

  const sendMessage = (contact: string, message: string) => {
    if ('WhatsAppBotApi' in window) {
      ; (window.WhatsAppBotApi as any).sendMessage(contact, message)
    }
  }

  const storeProfile = (profile: ProfileType | Profile) => {
    if ('DesktopApi' in window) {
      if (profile instanceof Profile || Object.values(profile).some((value) => typeof value === 'function')) {
        profile = JSON.parse(JSON.stringify(profile))
      }
      ; (window.DesktopApi as any).storeProfile(profile)
    }
  }

  /**
   * Define as configurações do usuário no Desktop.
   *
   * @param {any} userControls - As configura es do usu rio.
   * @return {void}
   */
  const setUserControls = (userControls: any) => {
    if ('DesktopApi' in window && (window.DesktopApi as any).setUserControls) {
      (window.DesktopApi as any).setUserControls(userControls)
    }
  }

  const onMessageSend = (callback: (args: any) => void) => {
    if ('WhatsAppBotApi' in window) {
      ; (window.WhatsAppBotApi as any)?.onmessagesend((_: any, client: any) => {
        callback(client)
      })
    }
  }

  const onCart = (cart: CartType) => {
    if ('DesktopApi' in window) {
      ; (window.DesktopApi as any)?.onCart(cart)
    }
  }

  /**
   * Abre um link no navegador padrão.
  *
  * @param {React.MouseEvent<LinkProps, MouseEvent>} link - O link a ser aberto.
  * @return {void}
  */
  const openLink = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if ('DesktopApi' in window) {
      event.preventDefault()
        ; (window.DesktopApi as any)?.openLink(event.currentTarget.href)
    }
  }

  useEffect(() => {
    if ('navigator' in window) {
      setCanUseWhatsMenuDesktop(navigator.userAgent.includes('Windows NT 10'))
    }
  }, [])

  useEffect(() => {
    if (status === "authenticated" && 'DesktopApi' in window) {
      setUserControls(session.user?.controls)
    }
  }, [status, session]);

  return {
    sendMessage,
    storeProfile,
    setUserControls,
    onMessageSend,
    onCart,
    openLink,
    canUseWhatsMenuDesktop
  }
}