"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/cart-item.ts
var cart_item_exports = {};
__export(cart_item_exports, {
  default: () => CartItem
});
module.exports = __toCommonJS(cart_item_exports);
var CartItem = class {
  id;
  cartId;
  cartCode;
  productId;
  pizzaId;
  type;
  quantity;
  obs;
  details;
  name;
  controls;
  deleted_at;
  created_at;
  updated_at;
  constructor({ id, cartId, productId, pizzaId, type, quantity, obs, details, name, controls, deleted_at, created_at, updated_at }, cartCode) {
    ;
    this.id = id, this.cartId = cartId, this.cartCode = cartCode, this.productId = productId, this.pizzaId = pizzaId, this.type = type, this.quantity = quantity, this.obs = obs, this.details = details, this.name = name, this.controls = controls, this.deleted_at = deleted_at, this.created_at = created_at, this.updated_at = updated_at;
  }
  getTotal(withComplements) {
    if (withComplements && this.details.complements) {
      const totalComplements = this.details.complements.reduce((total, complement) => {
        complement.itens?.forEach((item) => {
          if (item.quantity) {
            total += item.value * item.quantity;
          }
        });
        return total;
      }, 0);
      return (this.details.value + totalComplements) * (this.type === "default" ? this.quantity : 1);
    } else {
      return this.details.value * (this.type === "default" ? this.quantity : 1);
    }
  }
};
