import DefaultCart from '@whatsmenu/entities/dist/cart'
import { DateTime } from 'luxon'
import { Session } from 'next-auth'
import { api } from 'src/lib/axios'
import { apiRoute } from '../utils/wm-functions'
import { IfoodOrderType } from '@whatsmenu/entities/dist/ifood-order'
import Profile, { ProfileType } from '@whatsmenu/entities/dist/profile'

export default class Cart extends DefaultCart {
  public async updateStatus(
    status: null | 'production' | 'transport' | 'canceled',
    session: Session | null
  ): Promise<string | null> {
    try {
      switch (this.origin) {
        case 'whatsmenu': {
          const { data } = await apiRoute(
            `/dashboard/carts/${this.id}/status`,
            session,
            'PATCH',
            { status, id: this.id }
          )
          this.status = data.cart.status
          return this.status
        }
        case 'ifood': {
          if (status) {
            let ifoodStatus = ''
            switch (status) {
              case 'transport':
                if (!this.addressId || !this.address) {
                  ifoodStatus = 'READY_TO_PICKUP'
                }
                if (this.address) {
                  ifoodStatus = 'DISPATCHED'
                }
                break
              case 'production':
                ifoodStatus = 'PREPARATION_STARTED'
                break
              case 'canceled':
                ifoodStatus = 'CANCELLED'
                break
              default:
                throw new Error('Status desconhecido para iFood')
            }
            if (status !== 'canceled') {
              await api.post(
                `/dashboard/ifood/order/${this.orderId}/updateStatus`,
                { status: ifoodStatus }
              )
            }
            this.status = status
            return this.status
          } else {
            throw new Error('Status não pode ser nulo para iFood')
          }
        }
        default:
          throw new Error('Origem desconhecida')
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  public async alterDate(session: Session | null, packageDate: string) {
    if (this.type === 'P') {
      try {
        const { data } = await apiRoute(
          `/dashboard/carts/${this.id}/package/date`,
          session,
          'PATCH',
          {
            package: DateTime.fromJSDate(
              new Date(
                `${packageDate} ${DateTime.fromSQL(this.packageDate, { setZone: true }).toFormat('HH:mm:ss')}`
              )
            ).toISO(),
            id: this.id,
          }
        )
        this.packageDate = DateTime.fromISO(data.cart.packageDate, {
          setZone: true,
        }).toSQL()
      } catch (error) {
        console.error(error)
        throw error
      }
    } else {
      throw 'É Necessário que o tipo do requests seja P'
    }
  }

  public async cancelOrUncancel(
    session: Session | null,
  ) {
    try {
      if (this.status !== 'canceled') {
        const status = await this.updateStatus('canceled', session)
        if (status === 'canceled') {
          this.status = status
        }
      } else {
        const status = await this.updateStatus(null, session)
        if (
          status === 'transport' ||
          status === 'production' ||
          status === 'canceled' ||
          status === null
        ) {
          this.status = status
        }
      }
    } catch (error) {
      console.error(error)
      return error
    }
  }

  public async production(session: Session | null) {
    if (this.status === null) {
      try {
        const status = await this.updateStatus('production', session)
        if (status === 'production') {
          this.status = status
        }
      } catch (error) {
        console.error(error)
        return error
      }
    }
  }

  public async transport(session: Session | null) {
    if (this.status !== 'transport') {
      try {
        const status = await this.updateStatus('transport', session)
        if (status === 'transport') {
          this.status = status
        }
      } catch (error) {
        console.error(error)
        return error
      }
    }
  }

  public async setPrinted(session: Session) {
    try {
      if (this.origin !== 'ifood') {
        const { data } = await apiRoute(
          `/dashboard/carts/${this.id}/print`,
          session,
          'PATCH'
        )
        this.print = JSON.parse(data.cart.print) ? 1 : 0
      }
    } catch (error) {
      console.error(error)
    }
  }

  static fromIfood({ order, profile, }: { order: IfoodOrderType; profile: Profile | ProfileType }): Cart {
    return new Cart(super.fromIfood({ order, profile }))
  }
}
