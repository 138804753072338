import { Session, UserType } from 'next-auth'

type Action = {
  type: 'update'
  payload: Partial<UserType>
  session?: Session
}

export const userReducer = (userState: any, action: Action) => {
  switch (action.type) {
    case 'update':
      return {
        ...userState,
        ...action.payload,
      }
    default:
      return userState
  }
}
